footer{
    padding-top: 60px;
    padding-bottom: 40px;
    background-color: #FF4F91;
}



footer .social a img{
    max-width: 40px;
    margin: 10px;
}

footer .copyright{
    font-size: 14px;
    font-weight: 600;
}

footer .copyright a{
    color: #fff;
    text-decoration: none;
}

@media only screen and (min-width: 1940px) {
    footer .copyright{
        font-size: 32px;
        font-weight: 600;
    }

}

@media only screen and (max-width: 768px) {
    footer .footer-logo img{
        max-width: 50px;
    }
   footer .social{
    margin-top: 10px;
   }
   footer .copyright{
    font-size: 12px;
    font-weight: 600;
}

  }
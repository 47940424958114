#banner {
    padding-top: 60px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

#banner .identity img {
    max-width: 60px;
}

#banner img.banner-img {
    max-width: 100%;
}

.section-title h2 {
    font-size: 3em;
    color: #FF4F91;
    letter-spacing: 4px;
    padding-bottom: 60px;
    text-shadow:
        -1px -1px 0 #fff,
        1px -1px 0 #fff,
        -1px 1px 0 #fff,
        1px 1px 0 #fff;
}

#banner h1 {
    font-size: 3.5em;
    color: #FF4F91;
    letter-spacing: 4px;
    text-shadow:
        -1px -1px 0 #fff,
        1px -1px 0 #fff,
        -1px 1px 0 #fff,
        1px 1px 0 #fff;
}

#banner .countdown {
    text-align: center;
    display: flex;
    justify-content: flex-start;
}

#banner .countdown-container {
    background-color: #fff;
    border: 3px solid #FF4F91;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#banner .countdown-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px;
    font-weight: bold;
}

#banner .countdown-item:not(:last-child) {
    border-right: 3px solid #FF4F91;
}

#banner .countdown .value {
    font-size: 24px;
    margin-left: 0px !important;
    padding: 2px;

}

#banner .countdown .label {
    font-size: 8px;
}

/* 
#banner .presale-box {
    background: linear-gradient(to right, #fff 94%, #FF4F91 94%);
    border: 3px solid #FF4F91;
    border-radius: 8px;
    padding: 10px;
} */

/* #banner .presale .input-group{
    background-color: #fff;
    border: 3px solid #FF4F91;
    border-radius: 8px;
} */

/* #banner .presale-box h6 {
    margin: 0px;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 0px;
    text-transform: uppercase;
} */
/* 
#banner .presale-box .value {
    margin-left: 20px;
} */

#banner .card {
    background-color: #fff;
    border: 3px solid #FF4F91;
    border-radius: 8px;
}

#banner .card .card-body {
    padding: 0px;
}

#banner .card .card-body .info {
    padding: 10px;
}



#banner .card p {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 800;
    line-height: 14px;
    margin-bottom: 0px;
    padding: 0px;
    background: none;
    border: none;
}

/* #banner .card .input-group-append{
    width: 100%;
    height: 100%;
} */

#banner .card .input-group-append button {
    color: #fff;
    padding: 50% 10px;
    border: none;
    background: #FF4F91;
   
}


#banner .presale-box button {
    border: none;
    background: none;
}

.tooltip-show,
.presale-tooltip-show {
    display: block;
    position: absolute;
    /* top: -50px; */
    /* Adjust the position as needed */
    left: 50%;
    /* Adjust the position as needed */
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 999;
}

.tooltip-hide,
.presale-tooltip-hide {
    display: none;
}

#banner .sol {
    background: linear-gradient(to right, #fff 50%, #FF4F91 50%);
    border: 3px solid #FF4F91;
    border-radius: 8px;
    padding: 10px;
}

#banner .home-list ul li {
    font-weight: 600;
}

#banner .home-cta a button {
    background-color: #FF4F91;
    text-transform: uppercase;
    color: #fff;
    margin: 0px 5px;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 12px 40px;
    border: none;
    border-radius: 8px;
}

#story {
    padding: 60px 0px;
    background-image: url('../assets/media/moon.png');
    background-position: top right;
    background-size: auto;
    background-repeat: no-repeat;
}

#story .story-img {
    z-index: 1;
}

#story .story-img img {
    max-width: 90%;
}

#story .story-box {
    margin-left: -100px;
    z-index: 0;
    padding: 80px 40px 80px 60px;
    background-color: #fff;
    border: 3px solid #FF4F91;
    border-radius: 12px;
}

#story .story-box p {
    font-weight: 600;
}

#divider {
    padding: 60px 0px;
    background: url('../assets/media/divider.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

#ppnomics {
    padding: 60px 0px;
}

#ppnomics .container {
    max-width: 1100px;
}

#ppnomics .info-box {
    padding: 20px;
    margin: 5px 5px 40px 5px;
}

#ppnomics .box-1 {
    color: #fff;
    background: url('../assets/media/box-1.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

#ppnomics .box-2 {
    color: #fff;
    background: url('../assets/media/box-2.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

#ppnomics .box-3 {
    color: #000;
    background: url('../assets/media/box-3.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

#ppnomics .info-box-alt {
    padding: 30px;
    margin: 5px 5px 40px 5px;
}

#ppnomics .box-4 {
    color: #000;
    background: url('../assets/media/box-4.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

#ppnomics .box-5 {
    color: #000;
    background: url('../assets/media/box-5.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

#ppnomics .box-4 h6 {
    font-size: 14px;
}

#ppnomics .box-5 .copy-btn {
    background-color: initial;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 0;
}

#ppnomics .pp-img {
    max-width: 60%;
    ;
}

#ppnomics .info-btn button {
    background-color: #FF4F91;
    text-transform: uppercase;
    color: #fff;
    margin: 5px;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
}

#ppnomics .row-2 {
    margin-top: 60px;
}

#nft {
    padding: 60px 0px;
}

#nft .para-box {
    /* margin-top: 100px; */
    padding: 60px 200px 60px 60px;
    background-color: #fff;
    border: 3px solid #FF4F91;
    border-radius: 12px;
}

#nft .para-box p {
    font-weight: 600;
}

#nft .nft-img {
    position: relative;
}

#nft .nft-img img {
    position: absolute;
    bottom: -60px;
    right: 0;
    max-width: 360px;
}

#nft .nft-collection {
    margin-top: 40px;
}

#nft .nft-collection img {
    max-width: 220px;
    margin: 10px;
}

#staking {
    padding: 60px 0px;

}

#staking .para-box-2 {
    /* margin-top: 100px; */
    padding: 60px 60px 60px 200px;
    background-color: #fff;
    border: 3px solid #FF4F91;
    border-radius: 12px;
}

#staking .para-box-2 p {
    font-weight: 600;
}

#staking .staking-img {
    position: relative;
}

#staking .staking-img img {
    position: absolute;
    bottom: -60px;
    left: 0;
    max-width: 360px;
}

#roadmap {
    padding: 60px 0px;
}

#roadmap .map {
    padding: 20px;
    background-color: #fff;
    border: 3px solid #FF4F91;
    border-radius: 12px;
    margin: 10px;
}

#roadmap .map h6 {
    color: #FF4F91;
    text-transform: uppercase;
    margin-bottom: 10px;
}

#roadmap .map ul li {
    font-weight: 600;
}

#roadmap .roadmap-img img {
    max-width: 100%;
}

#featured {
    padding: 60px 0px;
}

#featured .featured-img {
    max-width: 250px;
    margin: 5px;
}

@media only screen and (max-width: 768px) {
    .navbar.navbar-light {
        margin-top: 0px;
    }

    .section-title h2 {
        font-size: 2.5em;
    }

    #banner h1 {
        font-size: 2.5em;
    }

    #banner .sol {
        background: linear-gradient(to right, #fff 60%, #FF4F91 60%);
    }

    /* #banner .presale-box {
        background: linear-gradient(to right, #fff 88%, #FF4F91 88%);
    }

    #banner .presale-box button{
        margin-top: -20px;
    }

    #banner .presale-box h6 {
        font-size: 8px;
    } */

    #banner .card p {
        font-size: 8px;
    }

    #story {
        background-size: 80px;
    }

    #story .story-img img {
        max-width: 100%;
    }

    #story .story-box {
        margin-top: 20px;
        margin-left: 0px;
        padding: 20px;
    }

    #divider {
        padding: 20px 0px;
    }

    #ppnomics .info-box-alt {
        padding: 40px;
    }


    #ppnomics .box-5 {
        padding: 60px;
        color: #000;
        text-align: center;
        background: url('../assets/media/box-sm-5.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    #ppnomics .box-5 h6.token {
        font-size: 8px;
    }

    #ppnomics .row-2 {
        margin-top: 0px;
    }

    #nft .para-box {
        padding: 20px;
    }

    #nft .nft-img {
        text-align: center;
        margin-bottom: 20px;
    }

    #nft .nft-img img {
        position: static;
        max-width: 90%;
    }
    #ppnomics .pp-img{
        max-width: 90%;
    }


    #nft .nft-collection img {
        max-width: 150px;
        margin: 0px;
        padding: 5px;
    }


    #staking .staking-img {
        text-align: center;
    }

    #staking .staking-img img {
        position: static;
        max-width: 60%;
    }

    #staking .para-box-2 {
        margin-top: 20px;
        padding: 20px;
    }

}


.scroll-top {
    position: fixed;
    /* Positions the element relative to the browser window */
    bottom: 20px;
    /* Sets the distance from the bottom edge */
    right: 20px;
    /* Sets the distance from the right edge  */
    width: 40px;
    /* Sets the button width */
    height: 40px;
    /* Sets the button height */
    border-radius: 50%;
    /* Creates a circular button */
    background-color: #FF4F91;
    /* Sets the button background color */
    color: #fff;
    /* Sets the icon color */
    text-align: center;
    /* Centers the icon horizontally */
    line-height: 40px;
    /* Aligns the icon vertically in the button */
    cursor: pointer;
    /* Changes cursor to pointer on hover */
    opacity: 0.7;
    /* Sets initial opacity (semi-transparent) */
    transition: all 0.2s ease-in-out;
    /* Smooth transition on hover */
}

.scroll-top.show {
    opacity: 1;
    /* Sets full opacity on button visibility */
}

.scroll-top i {
    font-size: 20px;
    /* Sets the icon size */
}
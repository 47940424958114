.navbar.navbar-light {
    background-color: #FF4F91;
    margin-top: 30px;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.99);
}


.navbar.navbar-light ul li a {
    font-size: 2em;
    text-transform: uppercase;
    color: #fff !important;
    font-weight: 800;
}

ul.social li a img {
    max-width: 40px;
}

@media only screen and (min-width: 1940px) {

    .navbar.navbar-light ul li a {
       font-size: 3em;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 0px 3rem;
    }
    ul.social li a img {
        max-width: 60px;
    }
}

.social.navbar-nav {
    display: flex !important;
    flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {

    .container {
        max-width: 100%;
    }

    .navbar {
        padding: 0px;
        margin: 0px;
    }

    .navbar a img {
        max-width: 60px;
    }

    .navbar.navbar-light {
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.99);
    }

    .nav-link {
        padding: 0px;
    }

    .social a img {
        max-width: 40px;
    }

    .social {
        display: flex !important;
        flex-wrap: wrap;
    }


}
@font-face {
  font-family: 'RifficFree';
  src: url('./assets/fonts/RifficFree-Bold.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.container {
  max-width: calc(100vw - 6%); /* Subtract 6% from viewport width */
}


h1, h2, h3, h4, h5, h6, a, button{
  font-family: RifficFree;
  letter-spacing: 1px;
  margin: 0px;
}

span, p, ul li{
  font-family: "Montserrat", sans-serif;
  margin: 0px;
}

ul{
  padding-left: 1rem;
}